<template>

<v-row class="fill-height mt-1">

    <v-col>
      <v-sheet height="64">

      <v-toolbar flat color="opcion1">

             <span class="h2 mr-5"><strong>Calendario - Bitacoras Trabajo</strong></span>

             
             <v-btn dark color="info" class="mr-3" @click="dialogNuevaBitacora=true"><v-icon>add</v-icon></v-btn>

             <v-btn dark color="success" class="mr-3" @click="listar()"><v-icon>sync</v-icon></v-btn>

            <v-divider vertical class="mx-2"></v-divider>

            
             <v-btn outlined  class="mx-2" color="grey darken-2" @click="setToday">
               Today
             </v-btn>





            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                <v-icon>chevron_left</v-icon>
            </v-btn>

            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                <v-icon>chevron_right</v-icon>
            </v-btn>

             <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>

                

            
           

            <v-spacer></v-spacer>


           

            <v-flex lg2 xl2 md2 sm4 xs4>
                <v-select  v-model="type" :items="types" dense outlined 
                hide-details class="ma-2" label="type" right>
                </v-select>
            </v-flex>

            

               


            
            
            

      </v-toolbar>
    </v-sheet>

    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
       
      ></v-calendar>

      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark >
              <v-btn @click="edicion_disable=false" icon>
                <v-icon>edit</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>close</v-icon>
              </v-btn>
            
            </v-toolbar>
            <v-card-text>
            


              <v-text-field v-model="selectedEvent.descripcion" label="Descripcion" :disabled="edicion_disable">
              </v-text-field>

              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="selectedEvent.fecha"
                    label="Fecha"
                    prepend-icon="date_range"
                    :disabled="edicion_disable"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedEvent.fecha"
                    no-title
                    @input="menu2 = false"
                ></v-date-picker>
                </v-menu>


                <v-select
                    v-model="selectedEvent.status"
                    :items="arrayStatus"
                    :menu-props="{ top: true, offsetY: true }"
                    prepend-icon="label"
                    label="Estatus"
                    :disabled="edicion_disable"
                  ></v-select>

                   <v-select
                    v-model="selectedEvent.id_tipo_trabajo"
                    :items="items_TiposTrabajos"
                    prepend-icon="home_repair_service"
                    :rules="[rules.required]"
                    :error-messages="errorMessages"
                    label="Tipo Trabajo"
                ></v-select>


                  <v-menu
                            ref="menu"
                            v-model="menu_hora_esta"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            color="indigo"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="selectedEvent.hora_establecida"
                                label="Hora Establecida."
                                prepend-icon="timer"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            color="indigo"
                            v-if="menu_hora_esta"
                            v-model="selectedEvent.hora_establecida"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>


                          <v-text-field
                            v-model="selectedEvent.placa"
                            label="placa"
                            prepend-icon="closed_caption"
                          ></v-text-field>
                       

              
                          <v-text-field
                            v-model="selectedEvent.placa_anterior"
                            label="Placa anterior"
                            prepend-icon="backspace"
                          ></v-text-field>
                     







                   <v-row>






                </v-row>



            </v-card-text>





            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancelar
              </v-btn>

              <v-btn color="success" @click="ActualizarPromesaPago(selectedEvent)">
                Guardar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-menu>




         <v-dialog v-model="dialogNuevaBitacora" persistent max-width="880">
            <v-card>
              <v-toolbar flat color="red" class="text-center">
               <v-toolbar-title class="white--text  text-center"><strong>Nueva Bitacora</strong></v-toolbar-title>
               <v-spacer></v-spacer>
               <v-icon dark @click="dialogNuevaBitacora=false">close</v-icon>
              </v-toolbar>

              <v-card-text>

                 <v-card-text>
                          <v-container>
                               <v-row>


                                 <v-col  cols="12" sm="7" md="7">
                  
                                        <v-autocomplete
                                        v-model="editedItem.id_bitacora_cliente"
                                        :items="items_cliente"
                                        label="Cliente"
                                        item-value="value"
                                        item-text="text"
                                        persistent-hint
                                        prepend-icon="face"
                                        :rules="[rules.required]"
                                        :error-messages="errorMessages"
                                        @change="actualizarContactos()"
                                      >
                                        </v-autocomplete>
                                    </v-col>


                                    <v-col  cols="12" sm="6" md="4">
                                  
                                      <v-select
                                              v-model="editedItem.id_tipo_trabajo"
                                              :items="items_TiposTrabajos"
                                              prepend-icon="home_repair_service"
                                              :rules="[rules.required]"
                                              :error-messages="errorMessages"
                                              label="Tipo Trabajo"
                                              @change="HabilitarcamposTrabajo()"
                                          ></v-select>

                                    </v-col>


                                      






                                       
                          <v-col  cols="12" sm="5"  md="3">
                        
                              <v-menu
                              ref="menuFecha_bitacora"
                              v-model="menuFecha_bitacora"
                              :close-on-content-click="false"
                              :return-value.sync="date_bitacora"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.fecha"
                                      label="Fecha inicio"
                                      prepend-icon="event"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[rules.required]"
                                      :error-messages="errorMessages"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="editedItem.fecha" locale="es"
                                  no-title  @input="menuFecha_bitacora = false" scrollable>
                                  </v-date-picker>
                            </v-menu>


                          </v-col>



            

                     <v-col cols="12" sm="4" md="4">
                          <v-menu
                            ref="menu"
                            v-model="menu_hora_esta"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            color="indigo"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.hora_establecida"
                                label="Hora Establecida."
                                prepend-icon="timer"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            color="indigo"
                            v-if="menu_hora_esta"
                            v-model="editedItem.hora_establecida"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>

                  </v-col>


                  

                   <v-col  cols="12" sm="6" md="4">
                   
                      <v-select
                            v-model="editedItem.status"
                            :items="items_status"
                            prepend-icon="label"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                            label="status"
                        ></v-select>

                  </v-col>


                    <v-col cols="12" sm="5" md="5" lg="5" xl="5" class="pt-0">
                         
                    <v-btn x-small class="d-inline ml-15" @click="nuevoContacto()">nuevo</v-btn>
                     <v-combobox
                        class="d-inline"
                        v-model="editedItem.contacto"
                        :items="items_contacto"
                        label="Contacto"
                        :rules="[rules.required]"
                        :error-messages="errorMessages"
                    ></v-combobox>

                  </v-col>

                     <v-col cols="12" sm="4" md="4" lg="4" xl="4">

                          <v-select
                          class="pt-0"
                            v-model="editedItem.id_tecnicos"
                            :items="items_tecnicos"
                            attach
                            chips
                            label="Tecnicos"
                            multiple
                          ></v-select>

                  </v-col>


                    <v-col  cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      v-model="editedItem.placa"
                      label="placa"
                      prepend-icon="closed_caption"
                    ></v-text-field>
                  </v-col>

                    <v-col v-show="ver_campos_traslado==true" cols="12" sm="6" md="3">
                                          <v-text-field
                                            v-model="editedItem.placa_anterior"
                                            label="Placa anterior"
                                            prepend-icon="backspace"
                                          ></v-text-field>
                      </v-col>


                  

                     <v-col cols="12" sm="9" md="9" lg="9" xl="9" class="mt-n5">
                              <v-btn x-small class="d-inline ml-15" @click="nuevaDireccion()">nueva</v-btn>
                        <v-combobox
                            v-model="editedItem.direccion"
                            :items="items_direcciones"
                            label="Direccion"
                            prepend-icon="apartment"
                        ></v-combobox>

                  </v-col>


                     <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.descripcion_trabajo"
                      label="Descripcion trabajo"
                      prepend-icon="description"
                    ></v-text-field>
                  </v-col>

                  
                  <v-col  cols="12" sm="12" md="12">
                   
                        <v-select
                           class="pt-0"
                           prepend-icon="headphones_battery"
                            v-model="editedItem.id_accesorios_instalados"
                            :items="items_accesorios"
                            attach
                            chips
                            label="Accesorios"
                            multiple
                          ></v-select>

                  </v-col>

                

                  



                   <v-col cols="12" sm="5" md="5">
                      <span class="d-inline-flex mx-2 mt-5"><strong>Estado Bitacora:</strong></span>
                    <input type="radio" value="1" v-model="editedItem.activo" class="d-inline mx-1">Activo
                    <input type="radio" value="0" v-model="editedItem.activo" class="d-inline mx-1">Inactivo
 
                  </v-col>




  
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogNuevaBitacora=false">Cancelar</v-btn>
                  <v-btn  color="success" @click="GuardarBitacora()"> Guardar</v-btn>
                </v-card-actions>
              </v-card-text>

            </v-card>


        </v-dialog>

    </v-sheet>
 



  </v-col>
</v-row>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";


export default {
   components:{

    },
    data: () => ({

        //opciones del dialogo de fechas
        menu: false,
        dialogInicio:false,
        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

        menuFecha_bitacora:false,
        date_bitacora: new Date().toISOString().substr(0, 10),
        time: null,

        menu_hora_esta:false,
        errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           }

       },

       items_TiposTrabajos:[],
       arrayTiposTrabajos:[],
       ver_campos_traslado:false, //al cambiar equipo necesitaremos placa anterior 

       ver_campos_cambio:false, //al cambiar equipo necesitaremos serie-imei anterior

      items_status:[  {'value':'Agendado', 'text':'Agendado'},
                  {'value':'Procesando', 'text':'Procesando'},
                  {'value':'Cancelado', 'text':'Cancelado'},
                  {'value':'Realizado', 'text':'Realizado'}
                  ],

      ArrayTenicos:[],
      items_tecnicos:[], 

      items_contacto:[],
      items_direcciones:[],

      ArrayAccesorios:[],
      items_accesorios:[],


    editedItem: {
        fecha: new Date().toISOString().substr(0, 10),
        hora_establecida: '',
        hora_inicio: '',
        hora_fin: '',
        tiempo_espera:0,
        hora_llegada_tecnico:'',
        id_bitacora_cliente: '', //id
        direccion:'',

        id_tipo_trabajo:'', //id
        descripcion_trabajo:'',

        status:'',  //Realizado, Cancelado, Agendado
        etiqueta:'', //flojedad conexion, simcard dañado, equipo freezado, manipulacion equipo, mala instalacion

        id_tipo_equipo:'', //id

        //campos habilitados solo para cambio de equipo o traslado
        placa_anterior:'',
        serie_anterior:'',
        imei_anterior:'',
    

        id_avl:'',
        nombre:'',
        placa :'',
        flota :'',

        id_tecnicos:'',  //id
        observacion_operador:'',

        estilo:'',
        marca: '',
        modelo:'',
        color:'',
        anio:'',
        chasis:'',
        serie:'',
        imei:'',
        simcard:'',
        simcard_anterior:'',
        proveedor_simcard:'',

        id_accesorios_instalados:'',
        ubicacion_equipo:'',
        observacion_tecnico:'',

        activo:1,
        procesado_avl:false,  //aun nadie hizo cambios en AVL de esta bitacora
        creado:'',
        editado:''

      },

      
       
        focus: '',
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        value: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        events: [], //aqui se cargan las bitacoras
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],

        edicion_disable:true,
        arrayStatus: ['Agendado', 'Exitoso', 'Cancelado'],

        menu2:false,
        menu3:false,
       


        ArrayClientes:[],
        items_cliente:[],
        dialogNuevaBitacora:false,
        editedItemBitacora:{
                            id_agendapago_cliente:'',
                            fecha:new Date().toISOString().substr(0, 10),
                            descripcion:'',
                            status:'Agendado',
                            monto:0,
                            id_agendapago_ejecutivo:''
                        }
     

      }),

    computed: {
         ...mapState(['usuario', 'token']), 
    },
    watch: {

        
 

     },
    created () {

         this.listarClientes();
         this.listarTecnicos();
         this.listarAccesorios();
         this.listarTiposTrabajos();

         let fecha_hoy=new Date();

         let mes=fecha_hoy.getMonth();
        

         this.fecha_inicio=new Date();
          if(mes == 0){
              this.fecha_inicio.setMonth(11);
              this.fecha_inicio.setFullYear(fecha_hoy.getFullYear() -1);
          }else{
             this.fecha_inicio.setMonth(fecha_hoy.getMonth() -1);
          }

             this.fecha_fin=new Date();

            if(mes == 11){
                this.fecha_fin.setMonth(0);
                this.fecha_fin.setFullYear(fecha_hoy.getFullYear() +1);

            }else{
              this.fecha_fin.setMonth(fecha_hoy.getMonth() +1);
            }
       


    },
     mounted () {
      this.$refs.calendar.checkChange();
      this.listar();
    },
    methods: {
            ...mapMutations(['mostrarLoading','ocultarLoading']),

        listar(){


           let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'Accediendo a datos'});


         let me=this;

          me.arreglo=[];
          me.events=[];
          this.data_excel=[];
          axios.post('bitacoras',
                               { 
                                'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
                              },
             config
          ).then(function(response){
             console.log(response);
            me.arreglo=response.data;

             me.arreglo.map(function(x){

                        let color='indigo';

                        if(x.status==''){ color='gray';}

                       me.events.push({
                        name: x.id_bitacora_cliente.nombre_cliente,
                        start: new Date(x.fecha),
                        hora_inicio:x.hora_inicio,
                        color:color,
                        timed: true,
                        id_tipo_trabajo:x.id_tipo_trabajo._id,
                        contacto:x.contacto,
                        direccion:x.direccion,
                        placa:x.placa,
                        placa_anterior:x.placa_anterior,
                   
                        monto:x.monto,
                        descripcion:x.descripcion_trabajo,
                        id:x._id,
                        fecha: new Date(x.fecha).toISOString().substr(0, 10),
                        status:x.status
                    });
                 });


            me.ocultarLoading();
            me.dialogInicio=false;
          }).catch(function(error){
            console.log(error);
          });


      },



      listarClientes(){
                  
                  let me=this;

                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'Accediendo a datos'});

                  this.items_cliente=[];

                

                  axios.get('clientes-all',
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayClientes=response.data;


                      me.ArrayClientes.map(function(x){
                                  me.items_cliente.push({'value':x._id, 'text':x.nombre_cliente});
                            });

                      me.ocultarLoading();
                  }).catch(function(error){
                      console.log(error);
                  });

      },

      listarTiposTrabajos(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;
          this.items_TiposTrabajos=[];
          this.arrayTiposTrabajos=[];

         axios.get('trabajos', config
                    ).then(function(response){
                      console.log(response);
                      me.arrayTiposTrabajos=response.data;

                         me.arrayTiposTrabajos.map(function(x){
                                        me.items_TiposTrabajos.push({'text':x.nombre_trabajo, 'value':x._id});
                            });

                  me.listarTecnicos();

                  me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });



      },

      listarTecnicos(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;

        this.ArrayTenicos=[];
        this.items_tecnicos=[];  

          axios.get('tecnicos', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayTenicos=response.data;

                         me.ArrayTenicos.map(function(x){
                                        me.items_tecnicos.push({'text':x.nombre_tecnico, 'value':x._id});
                            });


                         me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });




      },

      listarAccesorios(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

          let me=this;


         this.ArrayAccesorios=[];
         this.items_accesorios=[];

         this.data_excel=[];

             axios.get('accesorios', config
                    ).then(function(response){
                      console.log(response);
                      me.ArrayAccesorios=response.data;

                         me.ArrayAccesorios.map(function(x){
                                        me.items_accesorios.push({'text':x.nombre_accesorio, 'value':x._id});
                            });

                        
                         me.ocultarLoading();
                    }).catch(function(error){
                      console.log(error);
                    });


      },

      actualizarContactos(){

          this.items_contacto=[];
          this.items_direcciones=[];
          this.itemsContact=[]; //almacena para enviar todos los contactos al backend
          let me=this;

        

            me.ArrayClientes.map(function(x){

                if(me.editedItem.id_bitacora_cliente==x._id){

                  let contactos=x.contactos;
                  me.itemsContact=x.contactos;

                  let direcciones=x.direcciones;
                  me.itemsDirecciones=x.direcciones

                  console.log(me.itemsDirecciones);

                

                  contactos.map(function(z){
                      me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
                  });

                  direcciones.map(function(z){

                    if(z.texto){
                      me.items_direcciones.push(z.texto);
                    }
                  

                  })

                }

            });

       

      },

      HabilitarcamposTrabajo(){

        this.ver_campos_traslado=false;
        this.ver_campos_cambio=false;
        let me=this;


        this.items_TiposTrabajos.map(function(x){

            if(x.value==me.editedItem.id_tipo_trabajo){

              let Evento_hacer=x.text;
                
                //Traslado de equipo

                  let StringTraslado='Traslado';
                  let posicionTraslado = Evento_hacer.indexOf(StringTraslado);
                  if (posicionTraslado !== -1){
                      console.log("Traslado equipo detectado: " + posicionTraslado+':'+Evento_hacer);
                      me.ver_campos_traslado=true;
                  }


                //Cambio de equipo

                  let StringCambio='Cambio';
                  let posicionCambio = Evento_hacer.indexOf(StringCambio);
                  if (posicionCambio !== -1){
                      console.log("Cambio equipo detectado: " + posicionCambio+':'+Evento_hacer);
                      me.ver_campos_cambio=true;
                  }



            }

        });
             
      },

      

      ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

      },



    
       viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },

      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },


      ActualizarPromesaPago(selectItem){

          let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

           let me=this;

          
           console.log(selectItem);



        axios.put(`agendapagos/${selectItem.id}`,
                { 
                  'fecha':new Date(selectItem.fecha+ " 01:00:00"),
                  'descripcion':selectItem.descripcion,
                  'status':selectItem.status,
                  'monto':parseFloat(selectItem.monto)
                  },
                  config

                  ).then(response=>{


                  console.log(response);
                   me.ocultarLoading();
                   me.dialogNuevaGestion=false;
                   me.selectedOpen = false;
                   me.listar();
        

                }).catch(e=>{
                      console.log(e)
                      this.mensaje=e
                });


      },

      

      

      
      GuardarBitacora() {
       
          //nueva bitacora 
      
           let me=this;

          if( 
                (this.editedItem.fecha!='')&&(this.editedItem.hora_establecida!='')&&
                (this.editedItem.id_bitacora_cliente!='')&&(this.editedItem.contacto!='')
                &&(this.editedItem.direccion!='')&&(this.editedItem.id_tipo_trabajo!='')&&
                (this.editedItem.status!='')
          ){ 


            
            //si ya tiene los ceros el reloj de horas verificar
            if(this.editedItem.hora_establecida!=''){  
                          this.editedItem.hora_establecida=this.editedItem.hora_establecida+":00";      
            }


          

              
            let fecha_insertar=this.editedItem.fecha+' '+this.editedItem.hora_establecida;

              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});

              let item_insertar= { 
                                          'fecha':new Date(fecha_insertar),
                                          'hora_establecida':this.editedItem.hora_establecida,
                                          'hora_inicio':this.editedItem.hora_inicio,
                                       
                                          'id_bitacora_cliente':this.editedItem.id_bitacora_cliente,

                                          'contacto':this.editedItem.contacto,
                                          'direccion':this.editedItem.direccion,
                                          'id_tipo_trabajo':this.editedItem.id_tipo_trabajo,
                                          'descripcion_trabajo':this.editedItem.descripcion_trabajo,
                                          'status':this.editedItem.status,

                                          'placa_anterior':this.editedItem.placa_anterior,
                                          'placa':this.editedItem.placa,
                                          'activo':this.editedItem.activo,
                                          'editado':this.usuario.nombre_ejecutivo+' a las:'+ me.ConvertirFecha(new Date())

                                          };


                  if(this.editedItem.id_tipo_equipo!=''){
                      item_insertar.id_tipo_equipo=this.editedItem.id_tipo_equipo;
                  }     
                  
                  
                  if(this.editedItem.id_accesorios_instalados!=''){

                            let separa=this.editedItem.id_accesorios_instalados;
                            separa=separa.toString();
                            separa=separa.split(",");

                            let ArrayEnviarAcesorios=[];

                          for(let i=0; i < separa.length; i++){
                            console.log('datos:ref_accesorio:'+separa[i]);
                            ArrayEnviarAcesorios.push({ref_accesorio:separa[i]});
                          }


                            console.log('datos de accesorios');
                            console.log(ArrayEnviarAcesorios);
                            item_insertar.id_accesorios_instalados=ArrayEnviarAcesorios;
                   }  

              
                   if(this.editedItem.id_tecnicos!=''){

                    let separa=this.editedItem.id_tecnicos;
                    separa=separa.toString();
                    separa=separa.split(",");

                    let ArrayEnviarTecnicos=[];

                  for(let i=0; i < separa.length; i++){
                    console.log('datos:ref_tecnico:'+separa[i]);
                    ArrayEnviarTecnicos.push({ref_tecnico:separa[i]});
                  }


                     console.log('datos de tecnicos');
                     console.log(ArrayEnviarTecnicos);
                      item_insertar.id_tecnicos=ArrayEnviarTecnicos;
                  }  


              axios.post('nueva-bitacora', item_insertar,
                                    config
                              ).then(response=>{

                                 this.editedItem={};
                                 this.dialogNuevaBitacora=false;
                                me.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                                  
                              });

          }else{

            alert('faltan campos obligatorios');
          }


        
      
      },

     






    }


 }
</script>